<template>
  <v-container fluid>
    <register-form/>
  </v-container>
</template>

<script>

export default {
  name: 'NewForm',
  components: {
    RegisterForm: () => import('@/components/forms/create/RegisterForm.vue'),
  },
}
</script>

<style scoped>

</style>